import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper'
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks'
import { AddCircleOutline } from '@mui/icons-material'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, CircularProgress, Dialog, Grid, IconButton, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import DataFormSelect from 'components/Core/DataFormSelect'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { MarketingIcon } from 'components/Icons/Icons'
import SkeletonTable from 'components/shared/table-skeleton/table-skeleton'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { formatDate, formatNumber } from 'utility/formatters'
import { SalesEditDialog } from './SalesEditDialog'
import { formatDeliveryPeriod, SALE_CATEGORY_CASH } from './SalesHelper'
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid'

export default function SalesWorkspace() {
    const { globalState, globalDispatch } = useContext(AppContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);
    const [currentCategory, setCurrentCategory] = useState("All Categories");

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    // ---

    const handleClickNewSale = async () => {
        setEditingSale({
            producer_token: globalState.producer_token,
            crop_year: currentYear,
            trade_date: new Date(),
        });
    }

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const handleLoadSelectedSale = async (id) => {
        // Refresh from API:
        try {
            // Get Sales:
            const _sales = await UsersApiHelper.users_selectObject("Sale", { id }, globalState);
            if (_sales?.length) {
                setEditingSale(_sales[0]);
            } else {
                console.error("Could not load sale", id);
            }
        } catch (err) {
            setEditingSale(null);
            handleError(err);
        }
    }

    const _loadSales = async () => {
        try {
            // Get Sales:
            const _salesFilter = {
                crop_year: currentYear,
                is_active: true,
            };
            if (filter?.commodity_name) {
                _salesFilter.commodity_name = filter.commodity_name;
            }
            if (filter?.commodity_name_list?.length == 1) {
                _salesFilter.commodity_name = filter.commodity_name_list[0];
            }
            const _sales = await UsersApiHelper.users_selectObject("Sale", _salesFilter, globalState);

            let _filteredSales = _sales;

            if (filter?.commodity_name_list?.length) {
                _filteredSales = _sales
                    .filter(obj => filter?.commodity_name_list?.includes(obj.commodity_name));
            }

            if (filter?.search_contract_number) {
                _filteredSales = _filteredSales
                    .filter(obj => obj.contract_number?.toLowerCase().includes(filter?.search_contract_number?.toLowerCase()));
            }

            // Order by trade_date desc
            _filteredSales = (_filteredSales.sort((a, b) => new Date(b.trade_date) - new Date(a.trade_date)));

            // Add dummy fields:
            _filteredSales = _filteredSales.map(s => {
                if (s?.category == SALE_CATEGORY_CASH) {
                    s.price_cash = (s?.price || 0) + (s?.basis || 0);
                } else {
                    s.price_cash = null;
                }
                return s;
            });

            setSales(_filteredSales);

            setIsLoading(false);
        } catch (err) {
            setSales(null);
            setIsLoading(false);
            handleError(err);
        }
    };

    const [sales, setSales] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (globalState?.producer_token) {
            setIsLoading(true);
            _loadSales();
        }
    }, [currentYear, globalState?.producer_token])

    // --- --- ---

    const [editingSale, setEditingSale] = useState(null);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...baseAcres };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    const isValid = () => {
        return true;
    }

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
    });

    useEffect(() => {
        setIsLoading(true);
        _loadSales();
    }, [filter]);

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleChange = async (sale) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            if (!sale.producer_token) {
                sale.producer_token = globalState.producer_token;
            }
            if (!sale.crop_year) {
                sale.crop_year = currentYear;
            }

            await api.callPost("marketing/Sale", sale, globalState);

            setIsLoading(true);
            _loadSales();
        } catch (err) {
            handleError(err);
        }
    }
    // --

    const columns = useMemo(() => [
        // {
        //     field: 'category',
        //     headerName: 'Category',
        //     flex: 1
        // },
        {
            field: 'trade_date',
            headerName: 'Trade Date',
            type: 'date',
            minWidth: 150,
            valueFormatter: (params) =>
                formatDate(params.value),
            flex: 1
        },
        {
            field: 'commodity_name',
            headerName: 'Commodity',
            minWidth: 0,
            flex: 1
        },
        {
            field: 'amount',
            headerName: 'Quantity',
            type: 'number',
            minWidth: 0,
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            align: 'right',
            headerAlign: 'right',
            flex: 0
        },
        {
            field: 'symbol',
            headerName: 'Symbol',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'price',
            headerName: 'Futures',
            minWidth: 120,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) =>
                params?.value ? formatNumber(params?.value, 4) : "",
        },
        {
            field: 'basis',
            headerName: 'Basis',
            type: 'number',
            minWidth: 120,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) =>
                params?.value ? formatNumber(params?.value, 4) : "",
        },
        {
            field: 'price_cash',
            headerName: 'Cash',
            minWidth: 120,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) =>
                params?.value ? formatNumber(params?.value, 4) : "",
        },
        {
            field: 'financial_institution_name',
            headerName: 'Buyer',
            minWidth: 200,
            flex: 2
        },
        {
            field: 'delivery_period_start',
            headerName: 'Delivery',
            minWidth: 120,
            flex: 2,
            valueFormatter: (params) =>
                (sales?.length) ? formatDeliveryPeriod(sales?.find(s => s.id == params?.id)) : null
        },
        {
            field: 'contract_number',
            headerName: 'Contract',
            minWidth: 0,
            flex: 2,
        },
        {
            field: '',
            headerName: '',
            renderCell: (params) => (
                <Box sx={{ textAlign: "right", width: "100%" }}>
                    <Stack direction='row'>
                        <IconButton onClick={() => handleLoadSelectedSale(params?.row?.id)}>
                            <EastOutlined />
                        </IconButton>
                    </Stack>
                </Box>
            ),
            flex: 1
        }
    ].filter(c => c), [sales]);

    return (
        <Box sx={{ minHeight: "800px" }}>
            <WorkspaceHeader
                title='Sales'
                breadCrumbs={[{
                    title: 'Marketing',
                    // path: '/vault',
                    icon: <MarketingIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12} lg={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton salesWorkspace marketing-position'>
                        <Typography variant='h1'>
                            Sales
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)}>
                                        {currentYear} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {getBudgetYears().map((year, index) =>
                                            <MenuItem key={index} onClick={() => {
                                                setCurrentYear(year);
                                                popupState.close();
                                            }}>
                                                {year}
                                            </MenuItem>)}
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                        <DataFormSelect
                            value={filter.commodity_name_list || []}
                            name="commodity_name_list"
                            isRequired={false}
                            onChange={filterHandleChangeState}
                            objectName="Commodity"
                            itemFilter={{ reinsurance_year: String(currentYear) }}
                            itemGetKey={(item) => item.commodity_name}
                            itemGetLabel={(item) => item.commodity_name}
                            doFetch={currentYear != null}
                            isLoading={false}
                            multiple={true}
                            limitTags={isSmall ? 2 : 3}
                            autoSelect={false}
                        />

                        <StatefulControlledTextField
                            label="Contract #"
                            name="search_contract_number"
                            isRequired={false}
                            defaultValue={filter?.search_contract_number || ''}
                            onChange={filterHandleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />

                        {isLoading && <CircularProgress />}
                        <Box></Box>
                    </Stack>
                </Grid>
            </Grid>

            {isLoading ? (
                // Render skeletons if data is loading
                <SkeletonTable skeletonTableRows={10} skeletonTableColumn={10} />
            ) : (
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <TilleyDataGrid
                        rows={sales || []}
                        columns={columns}
                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                        defaultPageSize={isMobile ? 5 : 10}
                        gridKey='sales-workspace-grid'
                        getRowId={(row) => row.id}
                        isLoading={isLoading}
                    />
                </div>
            )}

            <WorkspaceActionBar content={
                <>
                    <Stack direction="row" spacing={2}>
                        <div {...(!isValid() ? { inert: "true" } : {})}>
                            <Button variant="contained" onClick={handleClickNewSale} disabled={!isValid()}>
                                <AddCircleOutline sx={{ mr: 1 }} />
                                Add Sale
                            </Button>
                        </div>
                    </Stack>
                </>
            } />
            <Dialog open={Boolean(editingSale != null)}>
                <SalesEditDialog
                    producer_token={globalState.producer_token}//NOT override.
                    onChange={handleChange} onClose={() => { setEditingSale(null); setIsLoading(true); _loadSales(); }}
                    sale={editingSale} currentCategory={currentCategory}
                    crop_year={currentYear} />
            </Dialog>
            {confirmDialogComponent}
        </Box>
    )
}