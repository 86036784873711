import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import { AddOutlined, HourglassTopOutlined, QuestionMarkOutlined, Refresh, WarningOutlined } from '@mui/icons-material'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, CircularProgress, Dialog, Divider, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { downloadDocument, downloadDocumentByUrl, getFileIcon, getFileTypeName } from 'api-toolkit/FilesHelper'
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { StatefulControlledTextField } from "components/Core/StatefulControlledTextField"
import { FarmVaultIcon } from 'components/Icons/Icons'
import { DOCUMENT_SOURCE_EMAIL_DOMAIN, DOCUMENT_SOURCE_SECRET_THRESHOLD_DAYS } from 'components/Vault/DocumentSource/DocumentSourceHelper'
import ImportUploadFileDialog from 'components/Vault/Farm/components/Import/ImportUploadFileDialog'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import _ from 'lodash'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { ButtonLargeSquare } from 'mui-toolkit/ButtonLargeSquare'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatDate, formatDateContext, formatDateLong, formatDateTime, formatProducerName } from 'utility/formatters'
import { v4 } from 'uuid'
import { DOCUMENT_CATEGORY_FARMS, FINANCIAL_DOCUMENTS, TEMPLATE_URL_PATH } from './FinancialHelpers'
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid'

export default function FinancialDocumentsWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [isloadDocumentSourceEntriesLoading, setIsloadDocumentSourceEntriesLoading] = useState(true);
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    const documentFilters = ["Production", "Financials"];
    const [currentDocumentFilter, setCurrentDocumentFilter] = useState(documentFilters[0]);

    useEffect(() => {
        if (pathname == '/vault/documents') {
            setCurrentDocumentFilter(documentFilters[0]);
        } else if (pathname == '/financial/documents') {
            setCurrentDocumentFilter(documentFilters[1]);
        }
    }, [pathname]);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    // ---

    const [activeProducer, setActiveProducer] = useState();
    const selectActiveProducer = async () => {
        let _producer = await UsersApiHelper.users_selectObject("Member", { producer_token: globalState.producer_token }, globalState);

        if (_producer?.length) {
            setActiveProducer(_producer[0]);
        }
    };
    useEffect(() => {
        if (globalState?.producer_token) {
            selectActiveProducer();
        } else {
            setActiveProducer(null);
        }
    }, [globalState?.producer_token])


    // --- --- ---

    const [documents, setDocuments] = useState(null);
    const loadDocuments = async () => {
        try {
            setIsLoading(true);

            const _documentsFilter = {
                year: currentYear,
                is_active: true
            }
            const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, globalState);
            if (_documents?.length) {
                setDocuments(_documents);
            } else {
                setDocuments(null);
            }
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadDocuments();
        }
    }, [currentYear, globalState?.producer_token]);

    // ---

    const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);

    const handleDownloadClick = async (_financialDocument) => {
        try {
            await downloadDocument(_financialDocument, globalState)
                .then(isSuccess => {
                    if (!isSuccess) {
                        enqueueSnackbar("There is no file available for this document.", { preventDuplicate: true, variant: 'info' });
                    }
                });
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    const getDocumentByDocumentSourceEntry = useCallback((_documentSourceEntry) => {
        const _doc = documents?.find(doc => doc.id == _documentSourceEntry.id);
        return _doc;
    }, [documents]);

    const getFileIcon_DocumentSourceEntry = useCallback((_documentSourceEntry) => {
        const _financialDocument = getDocumentByDocumentSourceEntry(_documentSourceEntry);
        const icon = getFileIcon(_financialDocument);
        if (icon.props.sx.fontSize) {
            icon.props.sx.fontSize = "2.5rem";
        }
        return icon;
    }, [documents]);

    const handleDownloadClick_DocumentSource = async (_documentSourceEntry) => {
        const _financialDocument = getDocumentByDocumentSourceEntry(_documentSourceEntry);
        return await handleDownloadClick(_financialDocument);
    }

    const handlePublicDownloadClick = async (_publicDocument) => {
        const urlResponse = await UsersApiHelper.getPublicDocument({
            document_path: TEMPLATE_URL_PATH,
            document_name: _publicDocument?.templateFileName
        }, globalState);

        if (urlResponse?.url) {
            try {
                await downloadDocumentByUrl(urlResponse?.url, _publicDocument);
            } catch (err) {
                console.warn(err);
                enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
            }
        }
    }

    const handleDeleteDocumentClick = async (_financialDocument) => {
        confirmDialogActions.confirm({
            title: "Delete this document?",
            prompt: "Are you sure you want to delete this document?",
            promptDetail: null,
            callback: () => {
                handleDeleteDocumentClick_Intern(_financialDocument);
            }
        })
    }

    const handleDeleteDocumentClick_Intern = async (_financialDocument) => {
        try {
            _financialDocument.is_active = false;
            await UsersApiHelper.updateFinancialDocument(_financialDocument, globalState);
            loadDocuments();
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    // ---

    const [chatMessages, setChatMessages] = useState({
        chatTimestamp: Date(),
        messages: []
    });
    const sendChat = async (text) => {
        const _chatMessages = chatMessages?.messages;
        _chatMessages.push({ message: text, direction: "outgoing" });
        setChatMessages({
            chatTimestamp: Date(),
            messages: _chatMessages
        });

        UsersApiHelper.sendChatRequest({ text }, globalState)
            .then((chatResponse) => {
                const _chatMessages = chatMessages?.messages;
                _chatMessages.push({ message: chatResponse, direction: "incoming" });
                setChatMessages({
                    chatTimestamp: Date(),
                    messages: _chatMessages
                });
            });
    }

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const handleCopy = async (name, secret) => {
        try {
            // If the secret is specified name+secret@; else, just name@
            const emailAddress = (secret
                ? `${name}+${secret}@`
                : `${name}@`)
                + DOCUMENT_SOURCE_EMAIL_DOMAIN;
            navigator.clipboard.writeText(emailAddress);

            const message = `The email address ${emailAddress} has been copied to your clipboard.`;
            enqueueSnackbar(message, { preventDuplicate: true, variant: 'info' });
        } catch (err) {
            handleError(err);
        }
    };

    const [documentSources, setDocumentSources] = useState(null);
    const loadDocumentSources = async () => {
        try {
            setIsLoading(true);

            const _documentsFilter = {
                producer_token: globalState?.producer_token,
            }
            const _documents = await UsersApiHelper.users_search("DocumentSource", _documentsFilter, globalState);

            if (_documents?.length) {
                setDocumentSources(_documents);
            } else if (globalState?.producer_token && activeProducer) {
                // Create new address:

                const _userName = formatProducerName(activeProducer) || null;
                // Format user name:
                // - Only take chars before the @ (if exists)
                // - Remove all non-alphanumeric chars
                let _formattedUserName = _userName;
                if (_formattedUserName) {
                    const _atIndex = _formattedUserName.indexOf('@');
                    if (_atIndex > 0) {
                        _formattedUserName = _formattedUserName.substring(0, _atIndex);
                    }
                    _formattedUserName = _formattedUserName.replace(/[^a-zA-Z0-9]/g, '');
                }

                const _newDocumentSource = {
                    "id": v4(),
                    "producer_token": globalState?.producer_token,
                    "name": _formattedUserName,
                    "secret": "",
                    "secret_date": new Date(),
                };

                const api = new ApiHelper({
                    baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                    config: {},
                    sessionManager: _sessionManager,
                    // No caching:
                    _instance: null
                });
                await api.callPost("system/DocumentSource", _newDocumentSource, globalState);


                setDocumentSources([_newDocumentSource]);
            }
            setIsLoading(false);
        } catch (err) {
            handleError(err);
        }
    }

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadDocumentSources();
        }
    }, [globalState?.producer_token, activeProducer]);

    // ---

    const [documentSourceEntries, setDocumentSourceEntries] = useState(null);
    const loadDocumentSourceEntries = async () => {
        try {
            setIsloadDocumentSourceEntriesLoading(true);

            const _documentsFilter = {
                producer_token: globalState?.producer_token,
            }
            const _documents = await UsersApiHelper.users_search("DocumentSourceEntry", _documentsFilter, globalState);

            if (_documents?.length) {
                const _sortedDocuments = _documents.sort((a, b) => new Date(b.createdate) - new Date(a.createdate));
                setDocumentSourceEntries(_sortedDocuments);
            }
            setIsloadDocumentSourceEntriesLoading(false);
        } catch (err) {
            handleError(err);
        }
    }

    useEffect(() => {
        loadDocumentSourceEntries();
    }, [globalState?.producer_token]);

    // ---

    const updateDocumentSources = async (event, params, field) => {
        try {
            setIsLoading(true);
            const _documentUpdate = {
                ...params.row,
                producer_token: globalState?.producer_token
            };
            _documentUpdate[field] = event?.target?.value;

            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            await api.callPost("system/DocumentSource", _documentUpdate, globalState);

            // Refresh
            loadDocumentSources();

            setIsLoading(false);
        } catch (err) {
            handleError(err);
        }
    }

    // --- --- ---

    const columns = useMemo(() => [
        {
            field: 'name',
            headerName: 'Email Address',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <>
                    <StatefulControlledTextField
                        name={'name'}
                        required
                        defaultValue={params.value || ''}
                        onChange={(e) => updateDocumentSources(e, params, 'name')}
                        InputLabelProps={{ shrink: true }}
                    />
                    <Typography sx={{ ml: 1 }}>
                        @{DOCUMENT_SOURCE_EMAIL_DOMAIN}
                    </Typography>
                </>
            )
        },
        {
            field: 'secret',
            headerName: 'Privacy Token',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <StatefulControlledTextField
                    name={'secret'}
                    required
                    defaultValue={params.value || ''}
                    onChange={(e) => updateDocumentSources(e, params, 'secret')}
                    InputLabelProps={{ shrink: true }}
                />
            )
        },
        {
            field: 'secret_date',
            headerName: 'Last Updated',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                const secretDate = new Date(params.row.secret_date);
                const currentDate = new Date();

                // Calculate the difference in days
                const timeDifference = currentDate - secretDate;
                const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

                // Format the date for display (e.g., 'MMM dd, yyyy')
                const formattedDate = formatDateContext(secretDate);

                return (
                    <div style={{ color: daysDifference > 90 ? 'red' : 'inherit', display: 'flex', alignItems: 'center' }}>
                        {/* Display the formatted date */}
                        {formattedDate}

                        {/* Show a warning icon if the date is more than 90 days old */}
                        {daysDifference > DOCUMENT_SOURCE_SECRET_THRESHOLD_DAYS && (
                            <Tooltip title={`This date is more than ${DOCUMENT_SOURCE_SECRET_THRESHOLD_DAYS} days old`}>
                                <WarningAmberOutlinedIcon style={{ color: 'red', marginLeft: 8 }} />
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'icon',
            headerName: 'Action',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                params.row.id !== 'total' && (
                    <IconButton onClick={() => handleCopy(params?.row.name, params?.row.secret)}>
                        <ContentCopyOutlinedIcon className='copy-content' />
                    </IconButton>
                )
            ),
        },
    ], [documents]);

    const columnsDocumentSourceEntries = useMemo(() => [
        {
            field: 'document_name',
            headerName: 'File Name',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'id',
            headerName: 'File Type',
            flex: 1,
            minWidth: 100,
            // Include presigned url for download
            // Iconbutton with download icon
            renderCell: (params) => (<div style={{ whiteSpace: 'pre-line' }}>
                {getDocumentByDocumentSourceEntry(params.row)?.document_type_name || "Other"}
            </div>),
        },
        {
            field: 'notes',
            headerName: 'Message',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                // Show first [50] characters of the message
                <div style={{ whiteSpace: 'pre-line' }}>
                    <abbr title={params?.value}>
                        {params?.value.replace(/\n/g, '')?.substring(0, 50) + '...'}
                    </abbr>
                </div>
            ),
        },
        {
            field: 'createdate',
            headerName: 'Created',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => formatDateContext(params.value),
        },
        {
            field: 'id_download',
            headerName: 'Download',
            flex: 1,
            minWidth: 100,
            // Include presigned url for download
            // Iconbutton with download icon
            renderCell: (params) => (<>
                <IconButton onClick={() => handleDownloadClick_DocumentSource(params.row)}
                    sx={{ pl: 0, }}>
                    {getFileIcon_DocumentSourceEntry(params.row)}
                </IconButton>
            </>),
        },
    ], [documents, documentSourceEntries, getDocumentByDocumentSourceEntry, getFileIcon_DocumentSourceEntry]);

    return (
        <>
            <WorkspaceHeader
                title='Documents'
                breadCrumbs={[
                    {
                        title: 'Farm Vault',
                        // path: '/vault',
                        icon: <FarmVaultIcon />
                    },
                ]}
            />

            <Box sx={{ maxWidth: "98%" }}>

                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Your Documents
                    </Typography>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined" {...bindTrigger(popupState)}>
                                    {currentYear} <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {getBudgetYears().map((year, index) =>
                                        <MenuItem key={index} onClick={() => {
                                            setCurrentYear(year);
                                            popupState.close();
                                        }}>
                                            {year}
                                        </MenuItem>)}
                                </Menu>
                            </>
                        )}
                    </PopupState>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined" {...bindTrigger(popupState)}>
                                    {currentDocumentFilter} <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {documentFilters.map((doc, index) =>
                                        <MenuItem key={index} onClick={() => {
                                            setCurrentDocumentFilter(doc);
                                            popupState.close();
                                        }}>
                                            {doc}
                                        </MenuItem>)}
                                </Menu>
                            </>
                        )}
                    </PopupState>
                    <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                    {isLoading && <CircularProgress />}
                </Stack>

                <TilleyDataGrid
                    rows={documentSources || []}
                    columns={columns}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    defaultPageSize={isMobile ? 5 : 100}
                    gridKey='financial-document-sources'
                    getRowId={(row) => row.id}
                    getRowHeight={() => 80}
                    hideFooterPagination={true}
                    hideFooterSelectedRowCount={true}
                    isLoading={isLoading}
                />

                <Divider />

                <Stack direction="column">
                    {Boolean(FINANCIAL_DOCUMENTS?.length)
                        && (_.uniq(FINANCIAL_DOCUMENTS.map(cat => cat.category))).map((category, index) =>
                            (currentDocumentFilter == 'Production' || (currentDocumentFilter == 'Financials' && (index == 2 || index == 3))) &&
                            <Box key={index}>
                                <Box sx={{ mb: 1 }}>
                                    <Typography sx={{ textTransform: "capitalize", mb: 1 }}>
                                        {category}
                                    </Typography>

                                    <Box className='flexWrapContainer' sx={{ alignItems: "stretch", mb: 2 }}>
                                        {FINANCIAL_DOCUMENTS?.filter(doc => doc.category == category)?.map((template, documentIndex) => {
                                            const myDocuments = documents
                                                ?.filter(doc => doc.document_type_name == template.document_type_name);
                                            if (myDocuments?.length) {
                                                return myDocuments?.map((currentDocument, currentDocumentIndex) => {
                                                    const fileTypeName = getFileTypeName(currentDocument);
                                                    if (fileTypeName != "Other") {
                                                        return <Box key={documentIndex * 1000 + currentDocumentIndex}>
                                                            <ButtonLargeSquare
                                                                icon={getFileIcon(currentDocument)}
                                                                title={currentDocument?.document_type_name}
                                                                subHeader={formatDateContext(currentDocument?.lastupdate)}
                                                                onClick={() => handleDownloadClick(currentDocument)}
                                                                action={() => handleDeleteDocumentClick(currentDocument)}
                                                                actionIcon={<DeleteForeverOutlined />}
                                                            />
                                                        </Box>
                                                    } else {
                                                        return <Box key={documentIndex * 1000 + currentDocumentIndex}>
                                                            <ButtonLargeSquare
                                                                sx={{ backgroundColor: "#efefef" }}
                                                                icon={getFileIcon(currentDocument)}
                                                                title={template?.document_type_name}
                                                                subHeader={"Entered Manually"}
                                                                action={() => handleDeleteDocumentClick(currentDocument)}
                                                                actionIcon={<DeleteForeverOutlined />}
                                                            />
                                                        </Box>
                                                    }
                                                })
                                            } else {
                                                if (isLoading) {
                                                    return <Box key={documentIndex}>
                                                        <ButtonLargeSquare
                                                            sx={{}}
                                                            icon={<HourglassTopOutlined sx={{ fontSize: "3rem", color: "GrayText" }} />}
                                                            title={template?.document_type_name}
                                                            subHeader={"Loading..."}
                                                        />
                                                    </Box>
                                                }
                                                else if (template?.category == DOCUMENT_CATEGORY_FARMS) {
                                                    // Not found: optional documents
                                                    return <Box key={documentIndex}>
                                                        <ButtonLargeSquare
                                                            sx={{}}
                                                            icon={<QuestionMarkOutlined sx={{ fontSize: "3rem", color: "GrayText" }} />}
                                                            title={template?.document_type_name}
                                                            subHeader={template?.subHeader || " "}
                                                            disabled={true}
                                                            action={() => setIsUploadFileDialogOpen(true)}
                                                            actionIcon={<AddOutlined />}
                                                        />
                                                    </Box>
                                                } else {
                                                    // Not found: required documents
                                                    return <Box key={documentIndex}>
                                                        <ButtonLargeSquare
                                                            sx={{}}
                                                            icon={<WarningOutlined sx={{ fontSize: "3rem", color: "red", opacity: 0.7 }} />}
                                                            title={template?.document_type_name}
                                                            subHeader={template?.subHeader || " "}
                                                            disabled={true}
                                                            action={() => setIsUploadFileDialogOpen(true)}
                                                            actionIcon={<AddOutlined />}
                                                        />
                                                    </Box>
                                                }
                                            }
                                        })}
                                    </Box>
                                </Box>
                            </Box>)}
                    <br />
                </Stack >

                <Divider />


                <Stack direction="row" columnGap={2} sx={{ pt: 1.5 }}>
                    <Typography variant='h1'>
                        @ToTilley.com
                    </Typography>

                    <IconButton className='hiddenButton' size="large"
                        onClick={() => {
                            loadDocumentSourceEntries();
                        }}
                    >
                        <Refresh size='large' />
                    </IconButton>
                </Stack>

                <TilleyDataGrid
                    rows={documentSourceEntries || []}
                    columns={columnsDocumentSourceEntries}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    defaultPageSize={isMobile ? 5 : 10}
                    gridKey='financial-document-source-entries'
                    getRowId={(row) => row.id}
                    isLoading={isloadDocumentSourceEntriesLoading}
                />


                <Divider />

                <Typography variant='h1'>
                    Templates
                </Typography>

                <Box className='flexWrapContainer' sx={{ alignItems: "stretch" }}>
                    {FINANCIAL_DOCUMENTS
                        ?.filter(doc => doc.templateFileName)
                        ?.map((d, index) =>
                            <Box key={index}>
                                <ButtonLargeSquare
                                    icon={<EditNoteOutlined fontSize="large" />}
                                    title={d.title}
                                    subHeader={d.subHeader || " "}
                                    onClick={() => handlePublicDownloadClick(d)}
                                />
                            </Box>
                        )}
                </Box>

            </Box>

            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>
                    <PopupState variant="popover" popupId="file-menu-popper">
                        {(popupState) => (
                            <>
                                <Button variant="contained" onClick={() => {
                                    setIsUploadFileDialogOpen(true);
                                }}>
                                    <UploadFileOutlined sx={{ mr: 1 }} />
                                    Add Document
                                </Button>
                                {/* <Button variant="contained" {...bindTrigger(popupState)}>
                                    <ArrowDropDownOutlined sx={{ mr: 1 }} />
                                    Add Document
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        setIsUploadFileDialogOpen(true);
                                        popupState.close();
                                    }}>
                                        <UploadFileOutlined sx={{ mr: 1 }} /> Add a Document ...
                                    </MenuItem>
                                </Menu> */}
                            </>
                        )}
                    </PopupState>

                    {/* 
                    <PopupState variant="popper" popupId="chat-popper">
                        {(popupState) => (
                            <>
                                <Button variant="contained" {...bindToggle(popupState)}>
                                    <AssistantOutlined />
                                </Button>
                                <Popper {...bindPopper(popupState)}>
                                    <Box sx={{ minWidth: "300px", width: "25vw", height: "calc(100vh - 100px)", paddingRight: "16px", paddingTop: "4px" }}>
                                        <MainContainer sx={{ borderRadius: "8px !important" }}>
                                            <ChatContainer>
                                                <MessageList sx={{ flex: 1 }}>
                                                    {chatMessages?.messages?.map((m, index) =>
                                                        <MessageGroup key={index}
                                                            direction={m?.direction || "outgoing"}
                                                            sender={m?.direction == "incoming" ? "Tilley" : ""}
                                                        >
                                                            {m?.direction == "incoming" &&
                                                                <Avatar src="/favicon-32x32.png" name="Tilley" />
                                                            }
                                                            <MessageGroup.Messages>
                                                                <Message key={index}
                                                                    model={{
                                                                        message: m?.message || "",
                                                                        sentTime: "just now",
                                                                    }} />
                                                            </MessageGroup.Messages>
                                                        </MessageGroup>
                                                    )}
                                                </MessageList>
                                                <MessageInput placeholder='Ask me a question' attachButton={false} onSend={sendChat} />
                                            </ChatContainer>
                                        </MainContainer>
                                    </Box>
                                </Popper>
                            </>
                        )}
                    </PopupState> 
                    */}

                </Stack>
            </>} />


            {
                isUploadFileDialogOpen && <Dialog open={isUploadFileDialogOpen} fullWidth={isSmall} PaperProps={{ sx: {} }}>
                    <ImportUploadFileDialog year={currentYear} onClose={() => { setIsUploadFileDialogOpen(false); loadDocuments(); }} />
                </Dialog>
            }


            {confirmDialogComponent}
        </>
    )
}
