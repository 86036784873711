import { CloseOutlined, SaveOutlined } from "@mui/icons-material"
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, useMediaQuery } from "@mui/material"
import { AppContext } from "App";
import { _sessionManager, UsersApiHelper } from "api/ApiHelper";
import { StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useReducer, useState } from "react";
import { GROUP_NAMES } from "../MemberAccountWorkspace";
import { ApiHelper, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';

export const NewGroupDialog = ({
    users,
    onClose
}) => {

    const { globalState, globalDispatch } = useContext(AppContext);

    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [state, setState] = useState({});

    useEffect(() => {
        const _newState = { ...users[0] };
        _newState['UserName'] = users[0]?.email;
        _newState['GroupName'] = users[0]?.groupname;
        setState(_newState);
    }, [users]);

    // --- --- ---

    const isValid = () => {
        return Boolean(
            state?.UserName
            && state?.GroupName
        );
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // --- --- ---

    const [isLoading, setIsLoading] = useState(false);

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        const _newState = { ...state };
        _newState[event.target.name] = value;
        setState(_newState);
    }

    const handleChangeStateSelect = (event) => {
        const _newState = { ...state };
        _newState[event.target.name] = event.target.value;
        setState(_newState);
    }

    // --- --- ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const handleSaveClick = async () => {
        try {
            if (!validateEmail(state.UserName)) {
                return handleError("Please enter a valid email address in the username field");
            }
            // Instantiate API helper
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                _instance: null
            });

            // Make API POST request
            await api.callPost("Member/group/add", { UserName: state.UserName, GroupName: state.GroupName }, globalState);
            onClose && onClose();
        } catch (err) {
            handleError(err);
        }
    }

    return (<>
        <DialogTitle>
            Add Group
        </DialogTitle>

        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="Username"
                            name="UserName"
                            isRequired={true}
                            defaultValue={state?.UserName || ''}
                            variant="outlined"
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                            isLoading={isLoading}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <InputLabel id='groupname_Label'>Group Name</InputLabel>
                        <Select
                            labelId='groupname_Label'
                            value={state?.GroupName || ''}
                            name={"GroupName"}
                            onChange={handleChangeStateSelect}
                            label={'Group Name'}
                            disabled={false}
                            required
                        >
                            {GROUP_NAMES?.map((currentItem, index) => {
                                return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>
                    <CloseOutlined sx={{ mr: 1 }} />
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleSaveClick} disabled={!isValid()}>
                    <SaveOutlined sx={{ mr: 1 }} />
                    Save
                </Button>
            </Stack>
        </DialogActions>
    </>)
}