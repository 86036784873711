import { exportOptimizerResults } from "@datanac/datanac-api-toolkit/dist/insurance/optimizer/export";
import { getComboTitle } from "@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer";
import { UNIT_STRUCTURE_CODE_OU } from "@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper";
import styled from "@emotion/styled";
import { CloseOutlined, DownloadOutlined, EastOutlined, InfoOutlined, WestOutlined } from "@mui/icons-material";
import { Alert, Box, Button, Chip, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import { ApiHelper, UsersApiHelper } from "api/ApiHelper";
import { StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { getCellColor } from "components/StressTestVisualization/getCellColor";
import download from 'js-file-download';
import _ from "lodash";
import { useSnackbar } from "notistack";
import { Component, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { formatPrice } from "utility/formatters";
import { InsuranceService } from "../InsuranceService";
import { InsurancePlanComboDetailsPanel } from "./InsurancePlanComboDetailsPanel";
import TilleyDataGrid from "components/shared/DataGrid/TilleyDataGrid";

export const OptimizeInsuranceDialog = ({
    insuranceScenario,
    budgetCountyPractice,

    priorInsuranceScenario,
    priorInsurancePlans,

    onChange,
    onClose,
}) => {

    const { globalState } = useContext(AppContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isLg = useMediaQuery('(min-width:1440px)');
    const isXXL = useMediaQuery('(min-width:1537px)');

    // --- --- ---

    const isValidConstraints = () => {
        return stressTestParameters?.unit_structure_code
            && stressTestParameters?.loss_amount_maximum_per_acre;
    }

    const isValidOptimizeSelected = () => {
        return insurancePlanGridSelectionModel?.length;
    }

    const isComplete = () => {
        return false;
    }

    const [state, dispatch] = useReducer(propUpdateReducer, {});
    const [stressTestParameters, dispatchStressTestParameters] = useReducer(propUpdateReducer, {
        unit_structure_code: UNIT_STRUCTURE_CODE_OU
    });
    const handleChangeStressTestParameters = (event) => {
        dispatchStressTestParameters({
            type: 'update',
            payload: { key: event?.target?.name, value: event?.target?.value },
        });
    }

    const setStressTestParameters = (_parms) => {
        dispatchStressTestParameters({
            type: 'updateMany',
            payload: _parms,
        });
    }

    // --- --- ---

    const [progress, setProgress] = useState({});
    const _onStatusDebouncer = _.throttle(async ({ index, totalSteps, message }) => {
        setProgress({
            statusMessage: "#" + index + "/" + totalSteps + ": " + message,
            progress: Math.max(Math.round(index / totalSteps * 100), 1)
        });
    }, 500, { leading: true, trailing: false });

    const [optimizerResults, setOptimizerResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const _onProgressDebouncer = _.throttle(async (results) => {
        const __results = results.map((r, ii) => ({ ...r, id: ii }));
        setOptimizerResults(__results);
    }, 500, { leading: true, trailing: false });

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const _optimizeInsurance_intern = useCallback(async () => {
        // const _filteredMyFarms = producerFarmFieldCrops;
        // _filteredMyFarms.forEach(f => {
        //     f.reinsurance_year = budgetCountyPractice?.year;
        //     f.insured_share_percent = f.share_percent;
        //     f.production_cost_per_acre = budgetCountyPractice?.production_cost_per_acre;
        // });

        try {
            setIsLoading(true);
            const _InsuranceService = new InsuranceService({
                insuranceScenarios: [insuranceScenario],
                budgetCountyPractices: [budgetCountyPractice],
                crop_year: insuranceScenario.crop_year,
                apiConfig: globalState
            });
            await _InsuranceService.actions.loadInsuranceScenario()

            const _stressTestParameters = await loadStressTestParameters();

            const _insuranceScenarios = await _InsuranceService.actions.optimizeInsurance({
                constraints: {
                    reinsurance_year: insuranceScenario?.crop_year,
                    unit_structure_code: stressTestParameters?.unit_structure_code,
                    ..._stressTestParameters,

                    // // LIMIT results!!
                    // insurance_plan_filter: {
                    //     // insurance_plan_names: [INSURANCE_PLAN_NAME_YP, INSURANCE_PLAN_NAME_RP, INSURANCE_PLAN_NAME_RP_HPE, INSURANCE_PLAN_NAME_ECO_RP],
                    //     coverage_level_percents: [0.70, 0.75],
                    // },
                    // // LIMIT results!!
                    // MAX_ITERATIONS: 25
                },
                onStatus: _onStatusDebouncer,
                onProgress: _onProgressDebouncer,
                onError: (message) => {
                    handleError(message);
                },
            });

            if (_insuranceScenarios?.length && _insuranceScenarios[0]?.budgetCountyPractices?.length && _insuranceScenarios[0].budgetCountyPractices[0].optimizerResults?.length) {
                setOptimizerResults(_insuranceScenarios[0].budgetCountyPractices[0].optimizerResults.map((r, index) =>
                    ({ ...r, id: index })
                ))
            }

            setIsLoading(false);
            setProgress({});
        } catch (ex) {
            setIsLoading(false);
            handleError(ex);
            setProgress({});
        }
    }, [
        budgetCountyPractice,
        stressTestParameters,
        setProgress, enqueueSnackbar, setOptimizerResults,
        ApiHelper,
    ])

    const loadStressTestParameters = async () => {
        const filter = {
            producer_token: globalState.producer_token,
            insurance_scenario_id: insuranceScenario?.id,
            state_name: budgetCountyPractice?.state_name,
            county_name: budgetCountyPractice?.county_name,
            commodity_name: budgetCountyPractice?.commodity_name,
            type_name: budgetCountyPractice?.type_name,
            practice_name: budgetCountyPractice?.practice_name,
            is_active: true
        };
        const _stressTestParameters = await UsersApiHelper.users_selectObject("InsuranceStressTestParameters", filter, globalState);
        if (_stressTestParameters?.length) {
            setStressTestParameters(_stressTestParameters[0]);
            return _stressTestParameters[0];
        }
    }

    const _optimizeInsurance = async () => {
        setOptimizerResults([]);
        setProgress({
            statusMessage: "Optimizing...",
            progress: 1
        });

        _optimizeInsurance_intern();
    }

    // --- --- ---

    const statusPaneMemo = useMemo(() => {
        return (progress.progress > 0 && progress.progress < 100) && <div>
            <Typography variant="body2" noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: '#000000' }}>{progress.statusMessage}</Typography>
            <LinearProgress variant="determinate" value={progress.progress} />
        </div>
    }, [progress.progress, progress.statusMessage])

    const getFilteredResults = (_optimizerResults) => {
        return _optimizerResults?.filter(r =>
            (r.premium_cost_total_per_acre <= stressTestParameters?.premium_cost_total_per_acre
                || !stressTestParameters?.premium_cost_total_per_acre)
            && (0 - r.loss_amount_maximum_per_acre <= stressTestParameters?.loss_amount_maximum_per_acre
                || !stressTestParameters?.loss_amount_maximum_per_acre)
        ) || [];
    }

    const downloadDisplayDetails = async (_selectedInsurancePlanCombo) => {
        const exportResultsExcel = await exportOptimizerResults(globalState.myFarms, optimizerResults, _selectedInsurancePlanCombo.insurance_plan_names);
        download(exportResultsExcel.fileBuffer, "InsuranceOptimizerExport.xlsx");
    }

    // --- --- ---

    const STEP_KEY_SETUP = "setup";
    const STEP_KEY_OPTIMIZE = "optimize";
    const STEP_KEY_REVIEW = "review";

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'Setup',
            description: '',
            key: STEP_KEY_SETUP,
            isValid: isValidConstraints
        },
        {
            label: 'Optimize',
            description: isLg ? 'Choose one or two insurance strategies to analyze' : 'Select a strategy to analyze',
            key: STEP_KEY_OPTIMIZE,
            isValid: isValidOptimizeSelected
        },
        {
            label: 'Review',
            description: 'Compare plans to choose your preference',
            key: STEP_KEY_REVIEW,
            isValid: () => { return false; }
        },
    ];

    class HorizontalStepContent extends Component {
        render() {
            const thisStep = this?.props?.step;

            return (
                <>
                    {steps[activeStep]?.key == thisStep?.key &&
                        <Typography>{thisStep?.description}</Typography>}
                </>
            );
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (steps[activeStep]?.key == STEP_KEY_SETUP) {
            setOptimizerResults([]);
        } else if (steps[activeStep]?.key == STEP_KEY_OPTIMIZE && !optimizerResults?.length) {
            _optimizeInsurance();
        }
    }, [activeStep])

    // --- --- ---

    const [displayDetails, setDisplayDetails] = useState(null);
    const [insurancePlanGridSelectionModel, setInsurancePlanGridSelectionModel] = useState([]);

    const getCompareInsurancePlanCombo = (index) => {
        const id = insurancePlanGridSelectionModel[index];
        const selectedInsurancePlanCombo = optimizerResults?.find(c => c.id == id);
        return selectedInsurancePlanCombo;
    }

    const handleInsurancePlanComboSelected = (_insurancePlanCombos) => {
        // console.log(_insurancePlanCombos);
        // console.log(_insurancePlanCombos?.comboStressTestResults?.insurancePlans?.map(p => p?.insurancePlanParameters));

        const _insurancePlanParameters = _insurancePlanCombos?.comboStressTestResults?.insurancePlans?.map(p => p?.insurancePlanParameters);
        onChange && onChange(_insurancePlanParameters);
        onClose && onClose();
    }


    // --- --- ---

    const columns = useMemo(() => [
        {
            field: 'insurance_plan_names',
            headerName: 'Insurance Combo',
            flex: isMobile ? 1 : 2,

            renderCell: (params) => {
                const _insurancePlans = params.row.comboStressTestResults.insurancePlans.map(p => ({ ...p, ...p.insurancePlanParameters }));
                return <Stack sx={{ mt: 1, mb: 1 }} flexWrap="wrap" direction="row" columnGap={1} rowGap={1}>
                    {_insurancePlans?.map((currentInsurancePlan, index) =>
                        <Chip key={index} label={
                            getComboTitle([currentInsurancePlan])
                        } />
                    )}
                </Stack>
            },
        },
        {
            field: 'premium_cost_total_per_acre',
            headerName: 'Producer Premium /ac',
            valueFormatter: (params) =>
                formatPrice(params.value, 2)
            ,
            flex: 1
        },
        // {
        //     field: 'expected_net_insurance_benefit_per_acre',
        //     headerName: 'Expected Benefit /ac',
        //     valueGetter: (params) =>
        //         params.row.expected_net_insurance_benefit_per_acre
        //     ,
        //     valueFormatter: (params) =>
        //         formatPrice(params.value, 0)
        //     ,
        //     flex: 1,
        //     hide: isMobile,
        // },
        // {
        //     field: 'historical_expected_net_insurance_benefit_per_acre',
        //     headerName: 'Historical Benefit /ac',
        //     valueGetter: (params) =>
        //         params.row.historicalStressTestResults?.combinedStressTestResults?.cecl?.expected_net_insurance_benefit_per_acre
        //     ,
        //     valueFormatter: (params) =>
        //         formatPrice(params.value, 0)
        //     ,
        //     flex: 1,
        //     hide: isMobile,
        // },
        {
            field: 'expected_credit_loss_insured',
            headerName: 'Expected Loss /ac',
            valueGetter: (params) => (
                params.row.expected_credit_loss_insured
                / params.row.reported_acreage
            ),
            valueFormatter: (params) =>
                formatPrice(params.value, 0)
            ,
            flex: 1,
            hide: isMobile,
        },
        {
            field: 'loss_amount_maximum_per_acre',
            headerName: 'Max. Loss /ac',
            renderCell: (params) => (
                <Box key={params.row.id} sx={{
                    m: 0,
                    p: 1,
                    width: '100%',
                    height: '100%',
                    border: '1 px solid #c0c0c0',
                    backgroundColor: params.value >= 0 ? null
                        : getCellColor(params.value, 0 - stressTestParameters?.loss_amount_maximum_per_acre, true),
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {params.value < 0 ? formatPrice(params.value, 0) : formatPrice(0, 0)}
                </Box >
            ),
            flex: 1
        },
        {
            field: 'net_profit_insured_maximum_per_acre',
            headerName: 'Max. Profit /ac',
            renderCell: (params) => (
                <Box sx={{
                    m: 0,
                    p: 1,
                    width: '100%',
                    height: '100%',
                    border: '1 px solid #c0c0c0',
                    backgroundColor: getCellColor(params.value, 0 - stressTestParameters?.net_profit_insured_maximum_per_acre, true),
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {formatPrice(params.value, 0)}
                </Box >
            ),
            flex: 1
        },
        // {
        //     field: 'historical_expected_net_profit_insured_per_acre',
        //     headerName: 'Historical Profit /ac',
        //     valueGetter: (params) =>
        //         params.row.historicalStressTestResults?.combinedStressTestResults?.cecl?.expected_net_profit_insured_per_acre
        //     ,
        //     valueFormatter: (params) =>
        //         formatPrice(params.value, 0)
        //     ,
        //     flex: 1,
        //     hide: isMobile,
        // },

        {
            field: 'breakeven_projected',
            headerName: 'Breakeven',
            valueFormatter: (params) =>
                params.value == null ? '--' : formatPrice(params.value, 2)
            ,
            flex: 1,
            hide: isMobile,
        },
        {
            field: 'combo_stresstest_results',
            headerName: 'Details',
            renderCell: (params) => (
                <>
                    <Stack direction='row'>
                        <IconButton onClick={() => setDisplayDetails(params.row)} >
                            <InfoOutlined />
                        </IconButton>
                        <IconButton onClick={() => downloadDisplayDetails(params.row)} >
                            <DownloadOutlined />
                        </IconButton>
                    </Stack>
                </>
            ),
            flex: 1
        },
    ], []);

    const dataGridMemo = useMemo(() => {
        const INSURANCE_PLAN_GRID_MAX_SELECTIONS = isLg ? 2 : 1;

        return optimizerResults && optimizerResults.length > 0 &&
            <TilleyDataGrid
                rows={getFilteredResults(optimizerResults)}
                columns={columns}
                pageSizeOptions={[5, 10, 25]}
                defaultPageSize={isMobile ? 5 : 10}
                gridKey='optimize-insurance-grid'
                getRowId={(row) => row.id}
                autoHeight={false}
                sx={{ width: "100%" }}
                hideFooterPagination={false}
                hideFooterSelectedRowCount={true}
                columnVisibilityModel={{
                    premium_cost_total_per_unit: !isMobile,
                    expected_net_insurance_benefit_per_acre: !isMobile,
                    expected_credit_loss_insured: !isMobile,
                    breakeven_projected: !isMobile,
                    combo_stresstest_results: !isMobile,

                    historical_expected_net_insurance_benefit_per_acre: false,
                }}
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={insurancePlanGridSelectionModel}
                isRowSelectable={(parms) => (
                    insurancePlanGridSelectionModel.length < INSURANCE_PLAN_GRID_MAX_SELECTIONS
                    || insurancePlanGridSelectionModel.includes(parms.row.id)
                )}

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setInsurancePlanGridSelectionModel(newRowSelectionModel);
                }}
                isLoading={isLoading}
            />

    }, [
        isMobile, isXXL,
        optimizerResults.length,
        stressTestParameters?.premium_cost_total_per_acre, stressTestParameters?.loss_amount_maximum_per_acre,
        insurancePlanGridSelectionModel, setInsurancePlanGridSelectionModel,
        setDisplayDetails,
    ]);

    // --- --- ---

    return (
        <>
            <DialogTitle>
                Optimize Insurance
            </DialogTitle>

            <DialogContent sx={{
                paddingTop: (isXXL && [STEP_KEY_OPTIMIZE, STEP_KEY_REVIEW].includes(steps[activeStep]?.key)) ? 2 : "0px !important",
                paddingLeft: (!isXXL && steps[activeStep]?.key == STEP_KEY_REVIEW) ? 0.5 : 2,
                paddingRight: (isXXL && [STEP_KEY_OPTIMIZE, STEP_KEY_REVIEW].includes(steps[activeStep]?.key)) ? 2 : "4px !important",
            }}>
                {steps[activeStep].key == STEP_KEY_SETUP && <>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ p: 1, pb: 0 }}>Farm:</Typography>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormControl fullWidth sx={{ m: 0 }}>
                                <InputLabel id="unit_structure_code-label">Unit Structure Code</InputLabel>
                                <Select
                                    labelId="unit_structure_code-label"
                                    id="unit_structure_code"
                                    value={stressTestParameters?.unit_structure_code || null}
                                    label='Unit Structure Code'
                                    onChange={(e) => handleChangeStressTestParameters({ target: { name: 'unit_structure_code', value: e.target.value } })}
                                >
                                    <MenuItem value={'OU'}>OU</MenuItem>
                                    <MenuItem value={'BU'}>BU</MenuItem>
                                    <MenuItem value={'EU'}>EU</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ pl: 1 }}>Constraints:</Typography>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label="Max. Premium Price /ac"
                                    defaultValue={stressTestParameters?.premium_cost_total_per_acre || ''}
                                    onChange={handleChangeStressTestParameters}
                                    name="premium_cost_total_per_acre"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label="Max. Loss Amount /ac"
                                    defaultValue={stressTestParameters?.loss_amount_maximum_per_acre || ''}
                                    onChange={handleChangeStressTestParameters}
                                    name="loss_amount_maximum_per_acre"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>}

                {steps[activeStep].key == STEP_KEY_OPTIMIZE && <>
                    <Stack direction="column" justifyContent='space-between' sx={{ height: "100% !important" }}>
                        <Box sx={{ flexGrow: '1' }}>
                            {Boolean(progress?.progress || getFilteredResults(optimizerResults)?.length > 0) &&
                                dataGridMemo
                            }

                            {Boolean(!progress?.progress && getFilteredResults(optimizerResults)?.length == 0) &&
                                <Alert severity="warning">
                                    No results match your optimizer filter:<br />
                                    <Stack direction="column" spacing={2} alignItems="stretch" sx={{ mt: 1 }}>
                                        {stressTestParameters?.premium_cost_total_per_acre &&
                                            <Stack direction="row" spacing={2}>
                                                <Typography>Max. Premium Price /ac:</Typography>
                                                <Typography sx={{
                                                    justifySelf: "flex-end",
                                                    textAlign: "right",
                                                    flexGrow: "1"
                                                }}>
                                                    {formatPrice(stressTestParameters?.premium_cost_total_per_acre, 2)}
                                                </Typography>
                                            </Stack>}
                                        {stressTestParameters?.loss_amount_maximum_per_acre &&
                                            <Stack direction="row" spacing={2}>
                                                <Typography>Max. Loss Amount /ac:</Typography>
                                                <Typography sx={{
                                                    justifySelf: "flex-end",
                                                    textAlign: "right",
                                                    flexGrow: "1"
                                                }}>
                                                    {formatPrice(stressTestParameters?.loss_amount_maximum_per_acre, 2)}
                                                </Typography>
                                            </Stack>}
                                    </Stack>
                                </Alert>
                            }
                        </Box>
                        <Box>
                            {statusPaneMemo}
                        </Box>
                    </Stack>
                </>}

                {steps[activeStep].key == STEP_KEY_REVIEW && insurancePlanGridSelectionModel?.length > 1 && <>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={6} sx={{ borderRight: isSmall ? '2px solid #8CC63F' : '4px solid #8CC63F' }}>
                            <Box sx={{ mr: isXXL ? 2 : 1 }}>
                                <InsurancePlanComboDetailsPanel
                                    countyPractice={budgetCountyPractice}
                                    insurancePlanCount={Math.max(getCompareInsurancePlanCombo(0)?.comboStressTestResults?.insurancePlans?.length, getCompareInsurancePlanCombo(1)?.comboStressTestResults?.insurancePlans?.length)}
                                    displayInsurancePlanCombo={getCompareInsurancePlanCombo(0)}
                                    onChange={handleInsurancePlanComboSelected}
                                    isSplitScreen={insurancePlanGridSelectionModel?.length > 1} />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ ml: isXXL ? 2 : 1 }}>
                                <InsurancePlanComboDetailsPanel
                                    countyPractice={budgetCountyPractice}
                                    insurancePlanCount={Math.max(getCompareInsurancePlanCombo(0)?.comboStressTestResults?.insurancePlans?.length, getCompareInsurancePlanCombo(1)?.comboStressTestResults?.insurancePlans?.length)}
                                    displayInsurancePlanCombo={getCompareInsurancePlanCombo(1)}
                                    onChange={handleInsurancePlanComboSelected}
                                    isSplitScreen={true} />
                            </Box>
                        </Grid>
                    </Grid>
                </>}

                {steps[activeStep].key == STEP_KEY_REVIEW && insurancePlanGridSelectionModel?.length == 1 && <>
                    <InsurancePlanComboDetailsPanel
                        countyPractice={budgetCountyPractice}
                        insurancePlanCount={getCompareInsurancePlanCombo(0)?.comboStressTestResults?.insurancePlans?.length}
                        displayInsurancePlanCombo={getCompareInsurancePlanCombo(0)}
                        onChange={handleInsurancePlanComboSelected}
                        isSplitScreen={insurancePlanGridSelectionModel?.length > 1} />
                </>}
            </DialogContent>

            <DialogActions>
                {!isSmall &&
                    <Stepper activeStep={activeStep} orientation="horizontal"
                        sx={{ flexGrow: "1", mr: (isLg ? "200px" : 0) }}
                    >
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepLabel
                                    optional={null}
                                >
                                    {step.label || ''}
                                </StepLabel>
                                <HorizontalStepContent step={step}>
                                    <Typography>{step.description}</Typography>
                                </HorizontalStepContent>
                            </Step>))}
                    </Stepper>
                }

                <Stack direction="row" spacing={1} justifyContent='flex-end'>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClose} sx={{ mr: { xs: 0, lg: 5 } }}>
                        <CloseOutlined sx={{ mr: 1 }} />
                        Cancel
                    </Button>

                    <Button
                        variant="contained" color="primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        <WestOutlined sx={{ mr: 1 }} />
                        Back
                    </Button>

                    {!(steps[activeStep].key == STEP_KEY_REVIEW && insurancePlanGridSelectionModel?.length == 1) &&
                        <Button
                            variant="contained" color="primary"
                            onClick={handleNext}
                            disabled={!steps[activeStep].isValid()}
                        >
                            {activeStep === steps.length - 1 ? 'Next' : 'Next'}
                            <EastOutlined sx={{ ml: 1 }} />
                        </Button>}

                    {steps[activeStep].key == STEP_KEY_REVIEW && insurancePlanGridSelectionModel?.length == 1 && <>
                        <Button variant="contained"
                            onClick={() => handleInsurancePlanComboSelected(getCompareInsurancePlanCombo(0))}>
                            Select Plan <EastOutlined sx={{ ml: 1 }} />
                        </Button>
                    </>}
                </Stack>
            </DialogActions>
        </>
    )
}