import { useMemo } from 'react';
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid';

const getNameSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    return nameLabel;
}

const getStateCountySummary = params => {
    const farmRow = params.row;

    const label = `${farmRow?.location_county_name}, ${farmRow?.location_state_name}`;
    return label;
}

const getFarmRowSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    const commodityLabel = `${farmRow?.commodity_name} ${farmRow?.practice_name}`
    return String(`${nameLabel} ${farmRow.location_county_name} / ${farmRow.fsa_farm_number} T${farmRow.fsa_tract_number} (${farmRow.fsa_field_number}) / ${farmRow.basic_unit_number}-${farmRow.optional_unit_number} / ${commodityLabel}`);
}

export const COLUMNS_PRODUCERFARMCROPWITHHISTORY = [
    {
        field: 'yield_commodity_year',
        headerName: 'Year',
        flex: 1
    },
    {
        field: 'fsa_farm_number',
        headerName: 'FSN',
        flex: 1
    },
    {
        field: 'fsa_tract_number',
        headerName: 'Tract',
        flex: 1
    },
    {
        field: 'fsa_field_number',
        headerName: 'Field',
        flex: 1
    },
    // {
    //     field: 'location_county_name',
    //     headerName: 'County',
    //     valueGetter: getStateCountySummary,
    //     flex: 1
    // },
    {
        field: 'commodity_name',
        headerName: 'Commodity',
        flex: 1
    },
    {
        field: 'practice_name',
        headerName: 'Practice',
        flex: 1
    },
    {
        field: 'approved_yield',
        headerName: 'APH',
        flex: 1
    },
    {
        field: 'annual_yield',
        headerName: 'Yield',
        flex: 1
    },
    // {
    //     field: 'reported_acreage',
    //     headerName: 'Acres',
    //     flex: 1
    // },
    {
        field: 'year_reported_acreage',
        headerName: 'Acres',
        flex: 1
    },
    // {
    //     field: 'yield_acreage',
    //     headerName: 'Yield Acres',
    //     flex: 1,
    // },
];

export const COLUMNS_PRODUCERFARMCROPWITHHISTORY_SMALL = [
    {
        field: "fsa_farm_number",
        headerName: 'Unit',
        valueGetter: getFarmRowSummary,
        flex: 1
    },
];

export default function ProducerFarmCropWithHistoryDataGrid({
    producerFarmCropWithHistory,
}) {

    const memoizedColumns = useMemo(() => COLUMNS_PRODUCERFARMCROPWITHHISTORY, []);

    return (
        <>
            <TilleyDataGrid
                rows={producerFarmCropWithHistory || []}
                columns={memoizedColumns}
                pageSizeOptions={[5, 10, 25]}
                defaultPageSize={5}
                gridKey='producer-farm-crop-with-history'
                getRowId={(row) => row.id}
            />
        </>
    );
}
