import { DeleteForeverOutlined } from '@mui/icons-material';
import { Dialog, IconButton, useMediaQuery } from '@mui/material';
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from 'App';
import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks';
import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { BuyerProfileEditDialog } from './../Broker/BuyerProfileEditDialog';
import { useSnackbar } from 'notistack';
import { v4 } from 'uuid';
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid'

export default function BuyersGrid({
    producer_token,
    onAddBuyer = false,
    onClose = null
}) {
    const { globalState } = useContext(AppContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [buyers, setBuyers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [editingBuyerProfile, setEditingBuyerProfile] = useState(null);
    const minHeight = '450px';

    useEffect(() => {
        if (onAddBuyer) {
            handleAddBuyer();
        }
    }, [onAddBuyer]);

    const handleAddBuyer = () => {
        setEditingBuyerProfile({});
    };

    const handleCloseModal = () => {
        setEditingBuyerProfile(null);
        if (onClose) onClose();
    };

    useEffect(() => {
        try {
            if (producer_token) {
                _loadBuyers();
            } else {
                setBuyers(null);
            }
        } catch (err) {
            handleError(err);
        }
    }, [producer_token])

    const _loadBuyers = async () => {
        setIsLoading(true);
        const _buyers = await UsersApiHelper.users_search("Buyer", { producer_token: producer_token, is_active: true }, globalState);

        // Filter out buyer without an 'id'
        const buyers = _buyers?.filter(buyer => buyer.id !== null) || [];
        setBuyers(buyers);
        setIsLoading(false);
    };

    const handleChange_BuyerProfile = async (_newBuyerProfile) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            // Include additional fields if onAddBuyer is defined
            if (onAddBuyer) {
                _newBuyerProfile = {
                    id: v4(),
                    producer_token: producer_token,
                    is_active: true,
                    country_code: 'US',
                    ..._newBuyerProfile,
                };
            }

            // This is agent function. Do not specify producer_token or you MAY get impersonation conflict.
            const _apiConfig = {
                agent_token: globalState?.agent_token,
            };
            await api.callPost("marketing/buyer", _newBuyerProfile, _apiConfig);
            _loadBuyers();
        } catch (err) {
            // Identify duplicate key error by string
            if ((err.detail || err.message || JSON.stringify(err))?.toLowerCase()?.includes("duplicate")) {
                enqueueSnackbar("Buyer Profiles must be unique. This entry already exists. Try another selection.", { preventDuplicate: true, variant: 'error' });
            } else {
                handleError(err);
            }
        }
    }


    const handleRowDelete_BuyerProfile = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                _handleRowDeleteIntern_BuyerProfile(row);
            }
        })
    }, [confirmDialogActions]);

    const _handleRowDeleteIntern_BuyerProfile = async (row) => {
        const _row = { ...row };
        _row.is_active = false;

        handleChange_BuyerProfile(_row);
        _loadBuyers();
    };


    const columns = useMemo(() => [
        {
            field: 'financial_institution_name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'city_name',
            headerName: 'City',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'state_name',
            headerName: 'State',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => setEditingBuyerProfile(params?.row)}>
                        <EastOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleRowDelete_BuyerProfile(params?.row)}>
                        <DeleteForeverOutlined />
                    </IconButton>
                </>
            )
        }
    ], []);

    return (
        <>
            <TilleyDataGrid
                rows={buyers || []}
                columns={columns}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={isMobile ? 5 : 10}
                gridKey='buyers-grid'
                getRowId={(row) => row.id}
                isLoading={isLoading}
            />
            <Dialog open={Boolean(editingBuyerProfile)} PaperProps={{
                style: {
                    minHeight: minHeight,
                },
            }}>
                <BuyerProfileEditDialog buyerProfile={editingBuyerProfile} onClose={handleCloseModal}
                    onChange={handleChange_BuyerProfile} />
            </Dialog>
            {confirmDialogComponent}
        </>
    );
}