import { Box, Stack, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NavigationMenu, { NAVIGATION_MENU_DRAWER_WIDTH } from '../Menu/NavigationMenu'

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default function WorkspaceContainer({
    navMenu,
    children
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    return (
        <>
            <ScrollToTop />
            <Stack direction="row" sx={{ width: "99%" }}>
                <Box className='navigation leftNav'>
                    {navMenu}
                </Box>

                <Box className='body' sx={{
                    width: "100%",
                    p: 0, pr: 1,
                    overflow: 'clip',
                    ...(isSmall && { ml: 1, mr: 0 }),
                    ...(!isSmall && { ml: 3, mr: 0 }),
                }}>
                    {children}
                </Box>
            </Stack>
        </>
    )
}
