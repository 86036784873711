import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'App';
import { useMediaQuery } from '@mui/material';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { FarmVaultIcon } from 'components/Icons/Icons';
import { UsersApiHelper } from 'api/ApiHelper';
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid';

export default function BuyerWorkspace() {
    const { globalState } = useContext(AppContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    // --- --- ---

    const [buyers, setBuyers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    useEffect(() => {

        const _loadBuyers = async () => {
            try {
                setIsLoading(true);
                // Get Member:
                const _buyers = await UsersApiHelper.users_selectObject("Buyer", {}, globalState);
                setBuyers(_buyers);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setBuyers(null);
                handleError(err);
            }
        };

        _loadBuyers();
    }, [])

    // --- --- ---

    const columns = useMemo(() => [
        {
            field: 'financial_institution_name',
            headerName: 'Name',
            minWidth: 200,
            flex: 3
        },
        {
            field: 'city_name',
            headerName: 'City',
            minWidth: 150,
            flex: 3
        },
        {
            field: 'state_name',
            headerName: 'State',
            minWidth: 120,
            flex: 3
        }
    ], []);

    return (
        <>
            <WorkspaceHeader
                title='Buyers'
                breadCrumbs={[{
                    title: 'Marketing',
                    icon: <FarmVaultIcon />
                }]}
            />

            <TilleyDataGrid
                rows={buyers || []}
                columns={columns}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={isMobile ? 5 : 10}
                gridKey='buyer-workspace-grid'
                getRowId={(row) => row.id}
                isLoading={isLoading}
            />
        </>
    );
}