import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { AppContext } from 'App';
import { Autocomplete, Box, Button, Dialog, FormControl, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, useMediaQuery } from '@mui/material';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { FarmVaultIcon, MemberManagementIcon } from 'components/Icons/Icons';
import { AddCircleOutline, EastOutlined, SearchOutlined } from '@mui/icons-material';
import { ClientDialog, DataDictionaryDialog } from './ClientDialog';
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar';
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { v4 } from 'uuid';
import { ApiHelper, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { useSnackbar } from 'notistack';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { formatProducerName, formatProducerNameSearchFields } from 'utility/formatters';
import AgentAutoComplete from './AgentAutoComplete';
import CircularProgress from '@mui/material/CircularProgress';
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid';
import { useNavigate } from 'react-router-dom';

export default function ClientWorkspace() {
    const { globalState, globalDispatch } = useContext(AppContext);

    const navigate = useNavigate();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const [editingItem, setEditingItem] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    // ---

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);

    const [userGrants, setUserGrants] = useState([]);


    const [brokers, setBrokers] = useState(null);
    const [isLoadingBrokers, setIsLoadingBrokers] = useState(false);

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        filter_agent_token: globalState?.filter_agent_token || [globalState?.agent_token] || [],
        search_producer_name: globalState?.search_text_portfolio,
    });
    const selectedBroker = useMemo(() => {
        const _selectedBroker = brokers?.filter(broker =>
            filter?.filter_agent_token?.includes(broker?.agent_token.toLowerCase())
        ) || [];

        return _selectedBroker;
    }, [brokers, filter?.filter_agent_token, globalState?.agent_token]);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "filter_agent_token",
                value: filter?.filter_agent_token,//array
            },
        });
    }, [filter?.filter_agent_token]);

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });

        // Persist search text?
        if (event.target.name == 'search_producer_name') {
            persistSearchText(event?.target?.value || "");
        }
    }

    const persistSearchText = async (searchText) => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "search_text_portfolio",
                value: searchText,
            },
        });
    }

    const _loadClients = async () => {
        try {
            setIsLoadingCurrent(true);
            const _userGrantsDataFilter = {
                agent_token: globalState?.agent_token,
            };

            const promises = filter.filter_agent_token.map(async (agentToken) => {
                if (filter?.filter_agent_token && filter?.filter_agent_token.length) {
                    _userGrantsDataFilter.filter_agent_token = agentToken;
                }

                const apiClaims = {};
                if (globalState?.user_role != USER_ROLE_ADMIN) {
                    apiClaims.agent_token = globalState?.agent_token;
                }

                return await UsersApiHelper.users_search("Client", _userGrantsDataFilter, apiClaims);
            });

            const allResults = await Promise.all(promises);
            // Get distinct, from combined results.
            const distinctAllResults = _.uniqBy(allResults.flat(), 'producer_token');

            setUserGrants(distinctAllResults);
            setIsLoadingCurrent(false);
        } catch (error) {
            setIsLoadingCurrent(false);
            handleError(error);
        }
    };

    useEffect(() => {
        _loadClients();
    }, [filter]);


    const loadBrokers = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {
                    agent_token: globalState?.agent_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {};
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);

                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            }
        } catch (err) {
            setIsLoadingBrokers(false);
            setBrokers(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoadingBrokers(true);
            loadBrokers();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    // ---

    const setViewAccountProducer = async (currentProducer) => {
        if (currentProducer?.producer_token) {
            globalDispatch({
                type: 'setProducer',
                payload: {
                    producer_token: currentProducer?.producer_token,
                    agent_token: globalState?.agent_token
                },
            });

            if (globalState.is_broker) {
                const _selectedYear = globalState?.crop_year || getCurrentReinsuranceYear();
                navigate(`/marketing/position_summary/${currentProducer?.producer_token}/${_selectedYear}`);
            } else {
                navigate(`/vault`);
            }
        }
    }

    // ---

    const _formatProducerNameCallback = useCallback((row) => {
        return formatProducerName(row);
    }, []);

    const columns = useMemo(() => [
        {
            field: 'producer_token',
            headerName: 'Client',
            minWidth: 200,
            flex: 3,
            valueGetter: (params) => {
                return _formatProducerNameCallback(params.row);
            },
        },
        {
            field: 'manage',
            headerName: 'Settings',
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => { navigate(`/broker/clients/${params.row?.id}`) }}
                        disabled={Boolean(globalState?.producer_token) && globalState?.producer_token != params.row?.producer_token}>
                        <MemberManagementIcon />
                    </IconButton>
                </>
            ),
            flex: 1
        },
        {
            field: 'impersonate',
            headerName: 'View Account',
            renderCell: (params) => (
                <>
                    <Stack direction='row' spacing={4}>
                        <IconButton onClick={() => setViewAccountProducer(params.row)}>
                            <EastOutlined />
                        </IconButton>
                    </Stack>
                </>
            ),
            flex: 1
        }
    ], [globalState?.producer_token, globalState?.agent_token, navigate]);

    const filtereduserGrants = useMemo(() =>
        userGrants?.filter((row) => {
            if (filter?.search_producer_name) {
                return formatProducerNameSearchFields(row)?.toLowerCase().includes(filter?.search_producer_name.toLowerCase());
            }
            return true;
        }),
        [userGrants, filter?.search_producer_name]);

    const handleChangeAgentToken = async (agentTokens) => {
        let agents = agentTokens.map((item) => (item.agent_token));

        dispatchFilter({
            type: 'update',
            payload: { key: 'filter_agent_token', value: agents },
        });
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Clients'
                breadCrumbs={[{
                    title: 'Marketing',
                    icon: <FarmVaultIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton marketing-position'>
                        <AgentAutoComplete brokers={brokers} selectedBroker={selectedBroker} onChange={handleChangeAgentToken} />

                        <StatefulControlledTextField
                            label="Search"
                            name="search_producer_name"
                            isRequired={false}
                            defaultValue={filter?.search_producer_name || ''}
                            onChange={filterHandleChangeState}
                            sx={{ minWidth: '2in', maxWidth: '100%' }}
                            endAdornment={
                                <Tooltip title='Search'>
                                    <InputAdornment position='end'>
                                        <IconButton edge="end" color="primary">
                                            <SearchOutlined />
                                        </IconButton>
                                    </InputAdornment>
                                </Tooltip>
                            }
                        />

                        {isLoadingCurrent && <CircularProgress />}
                    </Stack>
                </Grid>
            </Grid>

            <TilleyDataGrid
                rows={filtereduserGrants || []}
                columns={columns}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={isMobile ? 5 : 10}
                gridKey='client-workspace-grid'
                getRowId={(row) => row.id}
                isLoading={isLoadingCurrent}
            />

            <WorkspaceActionBar content={<>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={() => { navigate(`/broker/clients/0`) }}
                    >
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Client
                    </Button>
                </Box>
            </>} />

        </>
    );
}