import React, { useContext, useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DataGridContext from "core/AppContext";
import SkeletonTable from "../table-skeleton/table-skeleton";
import { Box } from "@mui/material";

const TilleyDataGrid = ({
    gridKey,
    pageSizeOptions = [5, 10, 15, 25, 50, 100],
    defaultPageSize = 25,
    rows = [],
    columns = [],
    getRowId,
    columnVisibilityModel = {},
    isRowSelectable = () => true,
    checkboxSelection = false,
    disableRowSelectionOnClick,
    rowSelectionModel,
    onRowSelectionModelChange = () => { },
    getRowHeight = null,
    hideFooterPagination = false,
    hideFooterSelectedRowCount = false,
    sx = {},
    isLoading = false,
    skeletonTableColumn = 10,
}) => {
    const { getGridState, setGridState } = useContext(DataGridContext);

    const initialState = getGridState()[gridKey] || {
        pageSize: defaultPageSize,
        sorting: null,
    };

    const [paginationModel, setPaginationModel] = useState({
        pageSize: initialState.pageSize || defaultPageSize,
        page: 0,
    });

    const [sortModel, setSortModel] = useState(
        initialState.sorting ? [initialState.sorting] : [] // Ensure an empty array if no sorting is defined
    );

    useEffect(() => {
        const savedState = getGridState()[gridKey];
        if (savedState) {
            setPaginationModel((prev) => ({
                ...prev,
                pageSize: savedState.pageSize || defaultPageSize,
            }));
            setSortModel(savedState.sorting ? [savedState.sorting] : []); // Validate sorting before setting
        }
    }, [getGridState, gridKey, defaultPageSize]);

    const handlePaginationModelChange = (model) => {
        setPaginationModel(model);
        setGridState(gridKey, { pageSize: model.pageSize });
    };

    const handleSortModelChange = (model) => {
        setSortModel(model);
        setGridState(gridKey, { sorting: model[0] || null }); // Save only the first sorting object or null
    };

    const tableRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const rect = tableRef.current?.getBoundingClientRect();
            const scrollTop = window.scrollY;

            if (rect) {
                const tableTop = rect.top + scrollTop;
                const tableBottom = tableTop + rect.height;

                if (scrollTop >= tableTop && scrollTop <= tableBottom - 50) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return isLoading ? (
        <Box sx={{ p: 2 }}>
            <SkeletonTable skeletonTableRows={10} skeletonTableColumn={skeletonTableColumn} />
        </Box>
    ) :
        (
            <div ref={tableRef} className="Tilley-DataGrid-StickyHeader">
                <DataGrid
                    className={`data-grid ${isSticky ? "sticky-header" : ""}`}
                    rows={rows}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    pageSizeOptions={pageSizeOptions}
                    getRowId={getRowId}
                    autoHeight={true}
                    sx={sx}
                    columnVisibilityModel={columnVisibilityModel}
                    isRowSelectable={isRowSelectable}
                    checkboxSelection={checkboxSelection}
                    disableRowSelectionOnClick={disableRowSelectionOnClick}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    getRowHeight={getRowHeight}
                    hideFooterPagination={hideFooterPagination || false}
                    hideFooterSelectedRowCount={hideFooterSelectedRowCount || false}
                />
            </div>
        );
};

export default TilleyDataGrid;