import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, Chip, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FinancialIcon } from 'components/Icons/Icons'
import InputDropdownPair from 'components/InputDropdownPair'
import InputTextPair from 'components/InputTextPair'
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPracticeName, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { OBJECT_NAME_LOAN_ORIGINATION_MEMO } from '../../Financial/FinancialHelpers'
import { ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL } from '../../Financial//forms/BalanceSheetHelper'
import useFinanceReport from 'components/Financial/useFinanceReport'
import { EastOutlined } from '@mui/icons-material'
import { BudgetService } from 'components/Budget/BudgetService'
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid'

export default function LoanBookListWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const isPrint = useMediaQuery('print');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true }) && !isPrint;
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    // --- --- ---

    const [searchText, setSearchText] = useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    }

    // ---

    const [isLoadingUserGrants, setIsLoadingUserGrants] = useState(true);
    const [userGrants, setUserGrants] = useState([]);
    const [userGrantsTimestamp, setUserGrantsTimestamp] = useState(uuidv4());
    const loadUserGrants = async () => {
        try {
            const _userGrants = await UsersApiHelper.selectUserGrants(
                { user_id: globalState.agent_token },
                { user_role: globalState?.user_role, agent_token: globalState?.agent_token }
            );
            setUserGrants(_userGrants);
            setUserGrantsTimestamp(uuidv4());
            setIsLoadingUserGrants(false);
        } catch (err) {
            setIsLoadingUserGrants(false);
            setUserGrants([]);
            setUserGrantsTimestamp(uuidv4());
            handleError(err);
        }
    }

    const calculateActiveUserGrants = () => {
        if (userGrants?.length) {
            return userGrants
                .filter(ug => ug.is_active)
                .filter(ug => ug.producer_description?.toLowerCase()?.includes(searchText));
        } else {
            return [];
        }
    }

    useEffect(() => {
        loadUserGrants();
    }, [currentYear])

    // ---

    useEffect(() => {
        const loadBudgets = async () => {
            const activeProducers = calculateActiveUserGrants();
            for (var producer of activeProducers) {
                const { actions: budgetActions } = new BudgetService({
                    apiConfig: { ...globalState, producer_token: producer.producer_token },
                    year: currentYear
                });

                // TODO: async
                producer.budget = (await budgetActions.getBudget()) || {};
            }

            setUserGrants(activeProducers);
        }

        loadBudgets();
    }, [userGrantsTimestamp])

    // ---

    const getNameSummary = params => {
        const farmRow = params.row;

        const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
        return nameLabel;
    }

    const columns = useMemo(() => [
        {
            field: 'producer_description',
            headerName: 'Producer',
            flex: 2
        },
        {
            field: 'producer_email',
            headerName: 'Email',
            flex: 2
        },
        {
            field: 'reported_acreage',
            headerName: 'Acres',
            renderCell: (params) => {
                const currentProducer = params.row;
                return <Box sx={{ textAlign: "right", width: "100%" }}>
                    {formatNumber(currentProducer?.budget?.reported_acreage)}
                </Box>
            },
            flex: 0
        },
        {
            field: 'expenses_total',
            headerName: 'Loan Request',
            renderCell: (params) => {
                const currentProducer = params.row;
                return <Box sx={{ textAlign: "right", width: "100%" }}>
                    {currentProducer?.budget?.expenses?.total ? formatPrice(currentProducer?.budget?.expenses?.total) : "--"}
                </Box>
            },
            flex: 1
        },
        {
            field: 'CropProfiles',
            headerName: 'Crop Profiles',
            renderCell: (params) => {
                const currentProducer = params.row;
                return <Box alignItems='left'>
                    {!Boolean(currentProducer?.budget) && <CircularProgress size='1rem' />}
                    <Stack direction="row" spacing={1} sx={{ display: "flex", flexWrap: "wrap", gap: 1, p: 2 }}>
                        {currentProducer?.budget?.budgetCommodities?.map((currentCropProfile, index) =>
                            <Chip key={index} label={currentCropProfile?.commodity_name + ' ' + formatPracticeName(currentCropProfile?.practice_name)} />
                        )}
                    </Stack>
                </Box>;
            },
            flex: 2
        },
        {
            field: 'producer_token',
            headerName: '',
            renderCell: (params) => {
                return <Box display="flex" justifyContent='flex-end'>
                    <IconButton onClick={() => { navigate(`/lender/loan_book/loan_origination_memo/${params?.row?.producer_token}/${currentYear}`) }}>
                        <EastOutlined />
                    </IconButton>
                </Box>;
            },
            flex: 0
        },
    ], [navigate]);

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Loan Book'
                breadCrumbs={[]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton navigation'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoadingUserGrants && <CircularProgress />}
            </Stack>

            {Boolean(calculateActiveUserGrants()?.length) && <>
                <TilleyDataGrid
                    rows={calculateActiveUserGrants()}
                    columns={columns}
                    pageSizeOptions={[5, 10, 25]}
                    defaultPageSize={isMobile ? 5 : 10}
                    gridKey='loan-book-list'
                    sx={{ width: "99%", boxSizing: "border-box" }}
                    getRowId={(row) => row.id}
                    isLoading={isLoadingUserGrants}
                />
            </>}

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
