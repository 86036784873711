import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Box, Button, Dialog, Divider, FormControl, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import { UsersApiHelper, _sessionManager } from 'api/ApiHelper';
import { AppContext } from "App";
import DataFormSelect from 'components/Core/DataFormSelect';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { MemberManagementIcon } from 'components/Icons/Icons';
import { MODULE_MARKETING } from 'components/Menu/NavigationMenu';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import AgentAutoComplete from './AgentAutoComplete';
import BuyersGrid from './BuyersGrid';
import { ClientAccountNumberDialog } from './ClientAccountNumberDialog';
import CropProfileGrid from './CropProfileGrid';
import { USER_ROLE_ADMIN } from 'core/useSession';

// ---

export default function ClientEditWorkspace() {
    const { globalState } = useContext(AppContext);
    const { member_id } = useParams();

    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();
    const { enqueueSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(false);
    const [isBuyerModalOpen, setIsBuyerModalOpen] = useState(false);
    const [isCropProfileModalOpen, setIsCropProfileModalOpen] = useState(false);

    // ---

    const [member, dispatchMember] = useReducer(propUpdateReducer, {
        country_code: "US",
        is_active: true
    });

    // ---

    const [brokers, setBrokers] = useState(null);
    const [isLoadingBrokers, setIsLoadingBrokers] = useState(false);
    const selectedBroker = useMemo(() => {
        const _selectedBroker = brokers?.find(broker =>
            member?.agent_token?.toLowerCase() == broker?.agent_token.toLowerCase()
        ) || null;

        return _selectedBroker;
    }, [brokers, member?.agent_token, globalState?.agent_token]);

    const handleChangeAgentToken = async (agent) => {
        handleChangeState({ target: { name: "agent_token" } }, agent?.agent_token);
    }

    const loadBrokers = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {
                    agent_token: globalState?.agent_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {};
                // Brokers create clients and assign them to any chosen agent_token.
                // So do not specify agent_token OR producer_token here.
                const _apiConfig = {};
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            }
        } catch (err) {
            setIsLoadingBrokers(false);
            setBrokers(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoadingBrokers(true);
            loadBrokers();
        }
    }, [globalState?.agent_token, globalState?.user_role]);

    const loadPrimaryBroker = async () => {
        try {
            const _userGrants = await UsersApiHelper.selectUserGrants({
                producer_token: member?.producer_token,
                is_active: true
            }, globalState);

            if (_userGrants?.length) {
                const _primaryAgentToken = _userGrants[0]?.agent_token;
                dispatchMember({
                    type: 'update',
                    payload: { key: "agent_token", value: _primaryAgentToken }
                });
            }
        } catch (err) {
            handleError(err);
        }
    }

    useEffect(() => {
        if (member?.id && !member?.agent_token && (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN)) {
            setIsLoadingBrokers(true);
            loadPrimaryBroker();
        }
    }, [globalState?.agent_token, globalState?.user_role, member]);

    // --- --- ---

    const handleAddBuyer = () => {
        setIsBuyerModalOpen(true); // Open the buyer modal
    };
    const handleCloseBuyerModal = () => {
        setIsBuyerModalOpen(false); // Reset to false when buyer modal is closed
    };

    const handleAddCropProfile = () => {
        setIsCropProfileModalOpen(true); // Open the crop profile modal
    };

    const handleCloseCropProfileModal = () => {
        setIsCropProfileModalOpen(false); // Reset to false when crop profile modal is closed
    };

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    // ---

    const loadMember = async (_memberID) => {
        try {
            const _memberFilter = {
                id: _memberID || member_id
            }
            const members = await UsersApiHelper.users_selectObject("Member", _memberFilter, {});
            if (members?.length) {
                dispatchMember({
                    type: 'updateMany',
                    payload: members[0]
                });
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            dispatchMember({
                type: 'replace',
                payload: {}
            });

            handleError(err);
        }
    }

    useEffect(() => {
        if (member_id && member_id != 0 && !member?.id) {
            loadMember();
        } else if (member_id == 0 && !member?.id) {
            const _newMemberID = v4();
            dispatchMember({
                type: 'update',
                payload: { key: "id", value: _newMemberID },
            });

            setIsLoading(false); ``
        }
    }, [globalState, member?.id, member_id])

    const handleRowDelete_Account = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                handleRowDelete_Account_Intern(row);
            }
        })
    }, [confirmDialogActions]);

    const [editingItem, setEditingItem] = useState(null);

    const [dataDictionary, setDataDictionary] = useState([]);
    const _loadDataDictionary = async () => {
        try {
            if (member?.producer_token) {
                const _filter = {
                    producer_token: member?.producer_token,
                    is_active: true
                };

                const api = new ApiHelper({
                    baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                    config: {},
                    sessionManager: _sessionManager,
                    // No caching:
                    _instance: null
                });
                const _dataDictionary = await api.callPost("DataDictionary/Search", _filter, globalState);

                setDataDictionary(_dataDictionary);
            }
        } catch (err) {
            handleError(err);
        }
    }

    useEffect(() => {
        _loadDataDictionary();
    }, [member]);

    // ---

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (member[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatchMember({
                type: 'update',
                payload: { key: event.target.name, value: value }
            });

            const _newState = { ...member };
            _newState[event.target.name] = value;
            handleChangeClient(_newState);
        }
    }

    const handleChangeClient = async (account) => {
        try {
            if (account?.id) {
                const api = new ApiHelper({
                    baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                    config: {},
                    sessionManager: _sessionManager,
                    // No caching:
                    _instance: null
                });

                const _accountParams = { ...account };
                if (!_accountParams.producer_token) {
                    _accountParams.producer_token = v4();
                }
                // Brokers create clients and assign them to any chosen agent_token.
                // So do not specify agent_token OR producer_token here.
                // _accountParams.agent_token = globalState?.agent_token;

                // Brokers create clients and assign them to any chosen agent_token.
                // So do not specify agent_token OR producer_token here.
                const _apiConfig = {};
                await api.callPost("marketing/Member", _accountParams, _apiConfig);
            }
        } catch (err) {
            handleError(err);
        }
    }

    const handleChangeDataDictionary = async (account) => {
        try {
            const _newState = { ...account };
            const SECTION_TILLEY = "Tilley";
            // Create ID if not exists
            _newState['id'] = _newState['id'] || v4();
            _newState['producer_token'] = member?.producer_token;
            _newState['section'] = SECTION_TILLEY;
            _newState['module'] = MODULE_MARKETING;
            _newState['object'] = account.object;
            _newState['container'] = account.container;
            _newState['value'] = account.value;

            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            await api.callPost("marketing/DataDictionary", _newState, globalState);

            _loadDataDictionary();
        } catch (err) {
            handleError(err);
        }
    }

    const handleRowDelete_Account_Intern = async (row) => {
        const _newRow = { ...row };
        _newRow.is_active = false;

        // HandleChange will reload data
        handleChangeDataDictionary(_newRow);
    }

    // ---

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    return (<>
        <WorkspaceHeader
            title={member_id != 0 ? 'Manage Member' : 'New Member'}
            breadCrumbs={[
                {
                    title: 'Clients',
                    path: '/broker/clients',
                    icon: <MemberManagementIcon />
                },
            ]}
        />

        <Box sx={{ width: formMaxWidth }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <AgentAutoComplete brokers={brokers} selectedBroker={selectedBroker} onChange={handleChangeAgentToken} multiple={false} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="Business Name"
                            name="business_name"
                            isRequired={true}
                            defaultValue={member?.business_name || ''}
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="First Name"
                            name="first_name"
                            isRequired={true}
                            defaultValue={member?.first_name || ''}
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="Last Name"
                            name="last_name"
                            isRequired={true}
                            defaultValue={member?.last_name || ''}
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="City"
                            name="city"
                            isRequired={true}
                            defaultValue={member?.city || ''}
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className="clientState">
                    <DataFormSelect
                        value={member?.state_abbreviation}
                        name="state_abbreviation"
                        onChange={handleChangeState}
                        objectName="State"
                        itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                        itemGetKey={item => item.state_abbreviation}
                        itemGetLabel={item => item.state_abbreviation}
                        doFetch={true}
                        isLoading={isLoading}
                        isSmall={true}
                        multiple={false}
                    />
                </Grid>
            </Grid>

            <Divider />

            {Boolean(member?.producer_token) && <>
                <Typography variant='h6' sx={{ mb: 2 }}>Accounts</Typography>

                <Grid container spacing={2}>
                    {/* {Boolean(member?.producer_token) && <> */}
                    {(dataDictionary || [])
                        ?.map((data, index) => <Fragment key={index}>
                            {data.object == 'office_code' &&
                                <Grid item xs={12} md={6} key={index}>
                                    <FormControl fullWidth={true}>
                                        <StatefulControlledTextField
                                            label={"Office Code (" + data?.container + ")"}
                                            name="office_code"
                                            isRequired={true}
                                            defaultValue={data?.value || ''}
                                            onChange={handleChangeState}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Grid>
                            }

                            {data?.object == 'account_number' &&
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth={true}>
                                        <StatefulControlledTextField
                                            label={"Account Number (" + data?.container + ")"}
                                            name="account_number"
                                            isRequired={true}
                                            defaultValue={data?.value || ''}
                                            variant="outlined"
                                            onChange={handleChangeState}
                                            InputLabelProps={{ shrink: true }}
                                            endAdornment={
                                                <InputAdornment position="end" className='delete'>
                                                    <IconButton onClick={() => handleRowDelete_Account(data)} edge="end" color="secondary">
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            }
                        </Fragment>
                        )}

                    <Grid item xs={12} md={6} style={{ paddingTop: "8px" }}>
                        <Typography variant='body2' sx={{ mb: 1 }}>Add Account</Typography>
                        <Button fullWidth sx={{ borderRadius: '4pt', height: '53px', mt: 'auto' }} variant='text' color='secondary'
                            onClick={() => setEditingItem({})}>
                            <AddCircleOutline />
                        </Button>
                    </Grid>
                </Grid>

                <Divider />
            </>}
        </Box>

        <Box>
            {Boolean(member?.producer_token) && <>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 2, mr: 3 }}
                >
                    <Typography variant='h6' sx={{ mb: 1 }}>Crop Profiles</Typography>
                    <Button variant="outlined" onClick={handleAddCropProfile}>
                        <AddCircleOutline /> Add Crop Profile
                    </Button>
                </Box>
                <CropProfileGrid producer_token={member?.producer_token} onAddCropProfile={isCropProfileModalOpen} onClose={handleCloseCropProfileModal} />

                <Divider />

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 2, mr: 3 }}
                >
                    <Typography variant="h6">Buyers</Typography>
                    <Button variant="outlined" onClick={handleAddBuyer}>
                        <AddCircleOutline /> Add Buyer
                    </Button>
                </Box>
                <BuyersGrid
                    producer_token={member?.producer_token}
                    onAddBuyer={isBuyerModalOpen} // Pass state to control modal visibility
                    onClose={handleCloseBuyerModal}
                />
            </>}
        </Box>
        <Dialog open={Boolean(editingItem != null)}>
            <ClientAccountNumberDialog data={editingItem}
                onChange={handleChangeDataDictionary} onClose={() => setEditingItem(null)} />
        </Dialog>
        {confirmDialogComponent}
    </>);
}