import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";
import { Autocomplete, Box, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import { getBudgetYears } from 'components/Budget/helpers';
import DataFormSelect from 'components/Core/DataFormSelect';
import { MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from "react";

// ---

export const COMMODITY_NAME_WHEAT = "Wheat";

export const CropProfileEditDialog = ({
    cropProfile,
    onClose,
    onChange,
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, setState] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    // ---

    useEffect(() => {
        // Load default state:
        if (cropProfile) {
            const _cropProfile = {
                marketing_goal_units: 0,
                acreage_irrigated: null,
                yield_irrigated: null,
                acreage_non_irrigated: null,
                yield_non_irrigated: null,
                exchange: null,
                ...cropProfile,
            };
            setState(_cropProfile);
        } else {
            setState({});
        }
    }, [cropProfile]);

    const onSubmit = (e) => {
        if (!validateForm()) {
            return;
        }
        onChange && onChange(state);
        onClose && onClose();
    }

    // ---

    const handleChangeStateSelect = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        formStatusActions.setIsFormDirty(true, false);

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;

            if (event.target.name === 'commodity_name') {
                if (value !== COMMODITY_NAME_WHEAT) {
                    const _newState = { ...state }
                    _newState['exchange'] = '';
                }
            }

            setState(_newState);
        }
    }

    const handleChangeStateSelectDropdown = (event, value) => {
        const _newState = { ...state };
        _newState[event.target.name] = event.target.value;
        setState(_newState);
    }

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    const validateForm = () => {
        const validationRules = [
            { field: 'year', message: 'Year is required.' },
            { field: 'commodity_name', message: 'Commodity is required.' },
            { field: 'marketing_goal_units', message: 'Marketing Goal is required.' },
        ];

        for (const rule of validationRules) {
            if (!state?.[rule.field] && (rule.condition === undefined || rule.condition)) {
                enqueueSnackbar(rule.message, { variant: 'error' });
                return false;
            }
        }
        return true;
    };

    const exchangeArr = ['MW', 'K'];

    // ---

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    return (<>
        <DialogTitle>
            Crop Profile
        </DialogTitle>

        <DialogContent>
            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <InputLabel id='yearLabel'>Year</InputLabel>
                            <Select
                                labelId='yearLabel'
                                value={state?.year || ''}
                                name={"year"}
                                onChange={handleChangeStateSelectDropdown}
                                label={'Year'}
                                disabled={false}
                            >
                                {getBudgetYears().map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <DataFormSelect
                                value={state?.commodity_name || ''}
                                name="commodity_name"
                                isRequired={true}
                                onChange={handleChangeStateSelect}
                                objectName="Commodity"
                                itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                                itemGetKey={(item) => item.commodity_name}
                                itemGetLabel={(item) => item.commodity_name}
                                doFetch={true}
                                isLoading={false}
                                multiple={false}
                                limitTags={isSmall ? 2 : 3}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Label"
                                name="name"
                                isRequired={true}
                                defaultValue={state?.name || ""}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Marketing Goal (# Units)"
                                name="marketing_goal_units"
                                isRequired={false}
                                defaultValue={state?.marketing_goal_units || 0}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                {/* <Divider sx={{ my: 2 }} />

                <Typography variant='h6' style={{ marginBottom: '20px' }}>
                    Irrigated
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Acreage"
                                name="acreage_irrigated"
                                isRequired={false}
                                defaultValue={state?.acreage_irrigated || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Yield Avg"
                                name="yield_irrigated"
                                isRequired={false}
                                defaultValue={state?.yield_irrigated || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                <Typography variant='h6' style={{ marginBottom: '20px' }}>
                    Non-Irrigated
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Acreage"
                                name="acreage_non_irrigated"
                                isRequired={false}
                                defaultValue={state?.acreage_non_irrigated || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Yield Avg"
                                name="yield_non_irrigated"
                                isRequired={false}
                                defaultValue={state?.yield_non_irrigated || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid> */}

                {state?.commodity_name == COMMODITY_NAME_WHEAT && <Divider sx={{ my: 2 }} />}

                {state?.commodity_name == COMMODITY_NAME_WHEAT && <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            value={state?.exchange || null}
                            onChange={(event, newValue) => {
                                handleChangeStateSelect({ target: { name: 'exchange', value: newValue } });
                            }}
                            autoSelect
                            autoHighlight
                            options={exchangeArr || []}
                            getOptionLabel={(option) => option || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={'Choose Exchange'}
                                    name={"exchange"}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>}
            </Box>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>
    </>);
}